import { useDispatch } from "react-redux";
import EyeIcon from "../../../assets/icons/eye.svg";
import EyeSlashIcon from "../../../assets/icons/eye-slash.svg";
import { Box, Button, Input, InputAdornment, Typography } from "@mui/material";
import CommonModal from "../../common/modals/commonModal";
import { useEffect, useState } from "react";
import { changePassword } from "../../../controller/services/auth";
import toast from "react-hot-toast";
import TickCardIcon from "../../../assets/icons/tick-card.svg";
import { Formik } from "formik";
import { setLoading } from "../../../redux/slices/flags_slice";
import { setBreadCrumb } from "../../../redux/slices/common_slice";

const ChangePasswordSettings = () => {
  const [passwordVisibility, setPasswordVisibility] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const breadCrumbArr = [{ name: "Change password", to: "" }];
    dispatch(setBreadCrumb(breadCrumbArr));
  }, []);
  const handleSubmit = (values) => {
    dispatch(setLoading(true));
    const payload = {
      oldPassword: values.password,
      newPassword: values.newPassword,
    };
    changePassword(payload)
      .then((resp) => {
        setSuccessModalVisible(true);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return (
    <Box
      padding={"20px 30px"}
      sx={{
        width: {
          xs: "100%",
          sm: "100%",
          md: "100%",
          xl: "40%",
          lg: "40%",
        },
      }}
    >
      <Formik
        initialValues={{ password: "", newPassword: "", confirmPassword: "" }}
        validate={(values) => {
          const passRegex = RegExp("^.{8,15}$");
          const errors = {};
          if (!values.password) {
            errors.password = "Required";
          }
          if (!passRegex.test(values.newPassword)) {
            errors.newPassword = "Must contain at least 8 or more characters";
          }
          if (!values.newPassword) {
            errors.newPassword = "Required";
          }
          if (!values.confirmPassword) {
            errors.newPassword = "Required";
          }
          if (values.newPassword !== values.confirmPassword) {
            errors.confirmPassword =
              "New password and Confirm password should match.";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Box marginBottom={"20px"}>
              <Input
                type={passwordVisibility == "password" ? "text" : "password"}
                name="password"
                placeholder="Enter password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                endAdornment={
                  <InputAdornment position="end" sx={{ paddingRight: "10px" }}>
                    {passwordVisibility == "password" ? (
                      <img
                        src={EyeIcon}
                        onClick={() => setPasswordVisibility("")}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <img
                        src={EyeSlashIcon}
                        onClick={() => setPasswordVisibility("password")}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputAdornment>
                }
                // startAdornment={<img src={PasswordIcon} alt="password" />}
                inputProps={{
                  style: {
                    padding: "10px",
                  },
                }}
                sx={{
                  padding: "10px 5px 10px 20px",
                  background: "#eff6f8",
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  "&:after": {
                    borderBottom: "none",
                  },
                  "&:before": {
                    borderBottom: "none",
                  },
                  "&:hover:not(.Mui-disabled, .Mui-error):before": {
                    borderBottom: "none",
                  },
                  "&.Mui-focused": {
                    borderBottom: "none",
                  },
                }}
              />
              {errors.password && touched.password && (
                <Typography textAlign={"left"} variant="body2" color="red">
                  {errors.password}
                </Typography>
              )}
            </Box>
            <Box marginBottom={"20px"}>
              <Input
                type={passwordVisibility == "newPassword" ? "text" : "password"}
                name="newPassword"
                placeholder="Enter new password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword}
                endAdornment={
                  <InputAdornment position="end" sx={{ paddingRight: "10px" }}>
                    {passwordVisibility === "newPassword" ? (
                      <img
                        src={EyeIcon}
                        onClick={() => setPasswordVisibility("")}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <img
                        src={EyeSlashIcon}
                        onClick={() => setPasswordVisibility("newPassword")}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputAdornment>
                }
                inputProps={{
                  style: {
                    padding: "10px",
                  },
                }}
                sx={{
                  padding: "10px 5px 10px 20px",
                  background: "#eff6f8",
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  "&:after": {
                    borderBottom: "none",
                  },
                  "&:before": {
                    borderBottom: "none",
                  },
                  "&:hover:not(.Mui-disabled, .Mui-error):before": {
                    borderBottom: "none",
                  },
                  "&.Mui-focused": {
                    borderBottom: "none",
                  },
                }}
              />
              {errors.newPassword && touched.newPassword && (
                <Typography textAlign={"left"} variant="body2" color="red">
                  {errors.newPassword}
                </Typography>
              )}
            </Box>
            <Box>
              <Input
                type={
                  passwordVisibility == "confirmPassword" ? "text" : "password"
                }
                name="confirmPassword"
                placeholder="Enter confirm password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
                endAdornment={
                  <InputAdornment position="end" sx={{ paddingRight: "10px" }}>
                    {passwordVisibility === "confirmPassword" ? (
                      <img
                        src={EyeIcon}
                        onClick={() => setPasswordVisibility("")}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <img
                        src={EyeSlashIcon}
                        onClick={() => setPasswordVisibility("confirmPassword")}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputAdornment>
                }
                // startAdornment={<img src={PasswordIcon} alt="password" />}
                inputProps={{
                  style: {
                    padding: "10px",
                  },
                }}
                sx={{
                  padding: "10px 5px 10px 20px",
                  background: "#eff6f8",
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  "&:after": {
                    borderBottom: "none",
                  },
                  "&:before": {
                    borderBottom: "none",
                  },
                  "&:hover:not(.Mui-disabled, .Mui-error):before": {
                    borderBottom: "none",
                  },
                  "&.Mui-focused": {
                    borderBottom: "none",
                  },
                }}
              />
              {errors.confirmPassword && touched.confirmPassword && (
                <Typography textAlign={"left"} variant="body2" color="red">
                  {errors.confirmPassword}
                </Typography>
              )}
            </Box>
            <Box textAlign={"left"}>
              <Button
                type="submit"
                disabled={isSubmitting || Object.entries(errors).length > 0}
                sx={{
                  width: "35%",
                  height: "55px",
                  marginTop: "20px",
                  background: "#38879f",
                  color: "#fff",
                  fontWeight: 600,
                  borderRadius: "10px",
                  "&.Mui-disabled": {
                    background: "#adcdd7",
                    color: "#fff",
                  },
                  "&:hover": {
                    backgroundColor: "#38879f",
                  },
                }}
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <CommonModal
        title="Password Changed Successfully"
        open={successModalVisible}
        icon={TickCardIcon}
        onClose={() => setSuccessModalVisible(false)}
        isSingleButton
        singleBtnText={"Done"}
      />
    </Box>
  );
};

export default ChangePasswordSettings;
